import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "connecting-destinations-to-sources",
      "style": {
        "position": "relative"
      }
    }}>{`Connecting Destinations to Sources`}<a parentName="h2" {...{
        "href": "#connecting-destinations-to-sources",
        "aria-label": "connecting destinations to sources permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Destinations control where data generated by a Source goes. You may decide exactly which Destinations a Source will
push data into, and turn them on and off at will.`}</p>
    <p>{`To learn more about Destinations, visit the `}<a parentName="p" {...{
        "href": "/docs/destinations/overview"
      }}>{`Destinations Documentation`}</a></p>
    <h3 {...{
      "id": "how-data-is-sent-to-a-destination",
      "style": {
        "position": "relative"
      }
    }}>{`How Data is Sent to a Destination`}<a parentName="h3" {...{
        "href": "#how-data-is-sent-to-a-destination",
        "aria-label": "how data is sent to a destination permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Destinations operate based on the configuration of the Destination itself. Additionally, Events and Sessions synced from a Source to a
Destination is limited to the data from that Source. In other words, if you have configured a Postgres destination, and
have it enabled on one of your two Sources, you'll only see Events and Sessions from the enabled Source in the Postgres database.`}</p>
    <p>{`Profiles are treated slightly differently when sending to Destinations. Profiles exist outside of time, and represent
individuals who have performed activities, potentially across multiple Sources. Keep this in mind when observing
total quantities of Profiles, or when attempting to join Profiles with Events or Sessions in a Destination warehouse.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      